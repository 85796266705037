import React, { useEffect } from 'react';
import { LoadingAnimated } from '../app/components/LoadingAnimated';
import { Button } from '../app/components/Buttons/Button';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import { useToast } from './toast';

// provide either isError or error. If the error object is provided, there will be a button to expand the error object.
export type LoadingWrapperProps = {
  isLoading: boolean;
  isError?: boolean;
  error?: any;
  children: React.JSX.Element;
};

export const LoadingWrapper = ({ isLoading, error, children, isError }: LoadingWrapperProps) => {
  const { toastSuccess } = useToast();

  const [jsonError, setJsonError] = React.useState<string | null>(null);

  const onClick = () => {
    if (jsonError) {
      setJsonError(null);
      return;
    }
    const timestamp = new Date().toLocaleString();
    const errorJson = JSON.stringify(error, null, 2);

    const err = `Error at ${timestamp}\n${errorJson}`;

    setJsonError(err);
  };

  useEffect(() => {
    setJsonError(null);
  }, [isLoading, error, children, isError]);

  const hasError = isError || error;

  const onCopy = async () => {
    await navigator.clipboard.writeText(jsonError || '');
    toastSuccess('Error details copied to clipboard.');
  };

  return (
    <>
      {hasError && (
        <div>
          <div>
            <h2 className="text-xl font-semibold text-red-700">Error fetching data.</h2>
          </div>
          {error && (
            <div className="mt-2">
              <Button onClick={onClick} color="danger">
                {jsonError && <>Hide Detail</>}
                {!jsonError && <>Click for more Detail</>}
              </Button>
            </div>
          )}
          {jsonError && (
            <>
              <div className="mt-2">
                <Button onClick={onCopy} color="info" icon={faCopy} size="xs">
                  Copy error details
                </Button>
              </div>
              <div className="mt-2">
                <textarea
                  readOnly={true}
                  value={jsonError}
                  rows={10}
                  className="block w-full rounded-md border-gray-300 pr-10 text-gray-900 placeholder-gray-300 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                />
              </div>
            </>
          )}
        </div>
      )}
      {!error && (
        <>
          {isLoading && <LoadingAnimated />}
          {!isLoading && children}
        </>
      )}
    </>
  );
};
