import { FormActionProps } from './FormActionProps';
import React from 'react';
import { useToast } from '../../../utils/toast';
import { Modal } from '../Modal';

export type SimpleItemEditModalParams<T> = {
  headerSingular: string;
  children: (props: FormActionProps<T>) => React.JSX.Element;
  getName?: (item: T) => string;
  closeModal: () => void;
  isVisible: boolean;
};

export function SimpleItemEditModal<T>({
  headerSingular,
  getName,
  children,
  closeModal,
  isVisible,
}: SimpleItemEditModalParams<T>) {
  const { toastError } = useToast();

  const onSuccess = (item: T) => {
    closeModal();
  };

  const onDeleted = (item: T) => {
    closeModal();
  };

  const onUnrecoverableError = (message: string | JSX.Element) => {
    toastError('Error', message);
  };

  return (
    <>
      <Modal width="sm:max-w-3xl" open={isVisible} closeModal={closeModal} closeModalText="Cancel">
        {children({
          onSuccess,
          onCancel: closeModal,
          onUnrecoverableError,
          onDeleted,
        })}
      </Modal>
    </>
  );
}
