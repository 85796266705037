import { createContext, useContext, useEffect, useState } from 'react';
import React from 'react';
import { ErrorOption, FieldPath, FieldValues, SubmitErrorHandler, SubmitHandler, useForm, ValidationRule } from 'react-hook-form';
import useField from '../Forms/FieldHook';
import { Patient } from '../../api/PatientApi';
import { ScheduleDefinition } from '../../api/ScheduleDefinitionApi';
import { useFlexworxConfig } from '../../../utils/flexworx-config';

const defaultDuration = 15;

export interface IAppointmentFormContext {
  timeField: any; // Form Field
  timeWatch: string | null;
  timeSetValue: (time: string) => void;
  dateField: any; // Form Field
  dateWatch: string | null;
  dateSetValue: (date: string) => void;
  sourceTime: string | null;
  sourceDate: string | null;
  patient: Patient | null; // NOT Form Field
  setPatient: (patient: Patient) => void;
  scheduleDefinitionId: any; // Form Field
  setScheduleDefinitionId: (scheduleDefinitionId: string | null) => void;
  scheduleDefinitionIdWatch: string | null;
  scheduleDefinition: ScheduleDefinition | null; // NOT Form Field
  appointmentId: any; // Form Field
  appointmentIdWatch: string | null;
  clinicLocationId: any; // Form Field
  clinicLocationIdWatch: string | null;
  setClinicLocationId: (clinicLocationId: string | null) => void;
  scheduleTypeId: any; // Form Field
  scheduleTypeIdWatch: string | null;
  setScheduleTypeId: (scheduleTypeId: string | null) => void;
  patientId: any; // Form Field
  appointmentTypeId: any; // Form Field
  appointmentTypeIdWatch: string | null;
  appointmentStatusId: any; // Form Field
  appointmentStatusIdWatch: string | null;
  handleSubmit: (
    onValid: SubmitHandler<FieldValues>,
    onInvalid?: SubmitErrorHandler<FieldValues>,
  ) => (e?: React.BaseSyntheticEvent) => Promise<void>;
  setError: (
    name: FieldPath<FieldValues> | `root.${string}` | 'root',
    error: ErrorOption,
    options?: { shouldFocus: boolean },
  ) => void;
  durationMinutes: any; // Form Field
  appointmentNote: any; // Form Field
  additionalInfo: any; // Form Field
  patientGlobalId: any; // Form Field
  register: any; // used for multi-field
  watch: any; // used for multi-field
  setValue: any; // used for multi-field
  control: any; // used for multi-field
  clearErrors: any; // used for multi-field
  getValues: any; // used for multi-field
  errors: any; // used for multi-field
  additionalAppointmentIndexFocus: number | null; // an index to hold onto to determine which index is focused at the moment
  setAdditionalAppointmentIndexFocus: (index: number | null) => void;
  additionalAppointmentIndexLock: number | null; // an index to hold onto to determine which index is the default focus at the moment
  setAdditionalAppointmentIndexLock: (index: number | null) => void;
  patientPhone1: any; // used for handling appointment patient validation
  patientClinicLocationId: any; // used for handling appointment patient validation
  additionalAppointmentCount: number;
}

const AppointmentFormContext = createContext<IAppointmentFormContext>({
  timeField: {},
  timeWatch: null,
  timeSetValue: () => {},
  dateField: {},
  dateWatch: null,
  dateSetValue: () => {},
  patient: {} as Patient,
  setPatient: () => {},
  scheduleDefinitionId: {},
  setScheduleDefinitionId: () => {},
  scheduleDefinition: {} as ScheduleDefinition,
  scheduleDefinitionIdWatch: null,
  appointmentId: {},
  appointmentIdWatch: null,
  clinicLocationId: {},
  clinicLocationIdWatch: null,
  setClinicLocationId: () => {},
  scheduleTypeId: {},
  scheduleTypeIdWatch: null,
  setScheduleTypeId: () => {},
  patientId: {},
  appointmentTypeId: {},
  appointmentTypeIdWatch: null,
  appointmentStatusId: {},
  appointmentStatusIdWatch: null,
  handleSubmit: () => async () => {},
  setError: () => {},
  durationMinutes: {},
  appointmentNote: {},
  additionalInfo: {},
  patientGlobalId: {},
  register: {},
  watch: {},
  setValue: {},
  control: {},
  clearErrors: {},
  getValues: {},
  errors: {},
  additionalAppointmentIndexFocus: null,
  setAdditionalAppointmentIndexFocus: () => {},
  additionalAppointmentIndexLock: null,
  setAdditionalAppointmentIndexLock: () => {},
  sourceTime: null,
  sourceDate: null,
  patientPhone1: {},
  patientClinicLocationId: {},
  additionalAppointmentCount: 0,
});

interface Focusable {
  getTimeWatcher: () => any;
  getDateWatcher: () => any;
  getTimeSetter: () => (time: string | null) => void;
  getDateSetter: () => (date: string | null) => void;
}

abstract class Focuser {
  public static Build(
    watch,
    setValue,
    additionalAppointmentIndexFocus: number | null,
    additionalAppointmentIndexLock: number | null,
  ): Focusable {
    if (additionalAppointmentIndexFocus !== null && additionalAppointmentIndexFocus !== undefined) {
      return new TargetAppointmentFocus('additionalAppointments', additionalAppointmentIndexFocus, watch, setValue);
    }
    if (additionalAppointmentIndexLock !== null && additionalAppointmentIndexLock !== undefined) {
      return new TargetAppointmentFocus('additionalAppointments', additionalAppointmentIndexLock, watch, setValue);
    }
    return new SourceAppointmentFocus(watch, setValue);
  }
}

class SourceAppointmentFocus implements Focusable {
  public getTimeWatcher: () => any;
  public getDateWatcher: () => any;
  public getTimeSetter: () => (time: string | null) => void;
  public getDateSetter: () => (date: string | null) => void;

  constructor(watch: any, setValue: any) {
    this.getTimeWatcher = () => watch(`appointmentTime`);
    this.getDateWatcher = () => watch(`appointmentDate`);
    this.getTimeSetter = () => (time: string | null) => setValue(`appointmentTime`, time);
    this.getDateSetter = () => (date: string | null) => setValue(`appointmentDate`, date);
  }
}

class TargetAppointmentFocus implements Focusable {
  public getTimeWatcher: () => any;
  public getDateWatcher: () => any;
  public getTimeSetter: () => (time: string | null) => void;
  public getDateSetter: () => (date: string | null) => void;

  constructor(field: string, index: number, watch: any, setValue: any) {
    this.getTimeWatcher = () => watch(`${field}[${index}].time`);
    this.getDateWatcher = () => watch(`${field}[${index}].date`);
    this.getTimeSetter = () => (time: string | null) => setValue(`${field}[${index}].time`, time);
    this.getDateSetter = () => (date: string | null) => setValue(`${field}[${index}].date`, date);
  }
}

export function AppointmentFormProvider({
  defaultValues,
  initialPatient,
  initialTime,
  initialDate,
  initialScheduleDefinitionId,
  children,
}: {
  defaultValues: FieldValues | undefined;
  initialPatient?: Patient;
  initialTime?: string;
  initialDate?: string;
  initialScheduleDefinitionId?: string | null;
  children: React.ReactNode;
}) {
  const { scheduleDefinitionFromId, scheduleDefinitions, appointmentTypeFromId } = useFlexworxConfig();

  defaultValues = {
    ...defaultValues,
    appointments: [],
  };

  if (initialPatient) {
    defaultValues = {
      ...defaultValues,
      patientId: initialPatient.id,
    };
  }

  if (initialTime) {
    defaultValues = {
      ...defaultValues,
      appointmentTime: initialTime,
    };
  }

  if (initialDate) {
    defaultValues = {
      ...defaultValues,
      appointmentDate: initialDate,
    };
  }

  if (initialScheduleDefinitionId) {
    defaultValues = {
      ...defaultValues,
      scheduleDefinitionId: initialScheduleDefinitionId,
      clinicLocationId: scheduleDefinitionFromId(initialScheduleDefinitionId)?.clinicLocationId,
      scheduleTypeId: scheduleDefinitionFromId(initialScheduleDefinitionId)?.scheduleTypeId,
    };
  }

  if (!defaultValues?.appointmentTypeId) {
    const apptTypeId = scheduleDefinitionFromId(initialScheduleDefinitionId ?? '')?.defaultAppointmentTypeId;
    if (apptTypeId) {
      defaultValues = {
        ...defaultValues,
        appointmentTypeId: apptTypeId,
      };
      const duration = appointmentTypeFromId(apptTypeId)?.durationMinutes;
      defaultValues = {
        ...defaultValues,
        durationMinutes: duration ?? defaultDuration,
      };
    }
  }

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    clearErrors,
    setValue,
    watch,
    control,
    getValues,
  } = useForm({ shouldFocusError: true, defaultValues: defaultValues });

  const [patient, setPatient] = useState<Patient | null>(initialPatient ?? null);

  const fieldArguments = (
    name: string,
    label: string,
    pattern?: ValidationRule<RegExp> | undefined,
    required?: string | ValidationRule<boolean> | undefined,
    setValueAs?: ((value: any) => any) | undefined,
  ) => {
    return { register, name, label, errors, clearErrors, setValue, pattern, required, setValueAs };
  };

  const timeField = useField<string>(fieldArguments('appointmentTime', 'Time', undefined, 'Time is required'));
  const timeWatch = watch('appointmentTime');

  const dateField = useField<string>(fieldArguments('appointmentDate', 'Date', undefined, 'Date is required'));
  const dateWatch = watch('appointmentDate');

  const scheduleDefinitionId = useField<string>(
    fieldArguments('scheduleDefinitionId', 'Schedule Definition', undefined, 'Schedule Definition is required'),
  );
  const scheduleDefinitionIdWatch = watch('scheduleDefinitionId');

  const appointmentId = useField<string>(fieldArguments('id', 'Appointment ID'));
  const appointmentIdWatch = watch('id', defaultValues?.id);

  // this is only changed when schedule definition id changes
  const [scheduleDefinition, setScheduleDefinition] = useState<ScheduleDefinition | null>(() => {
    if (scheduleDefinitionIdWatch) return scheduleDefinitionFromId(scheduleDefinitionIdWatch);
    return null;
  });

  // Clinic Location - Start
  const clinicLocationIdSetValue = (value: string) => {
    setValue('clinicLocationId', value);
    const scheduleDefinitionId =
      scheduleDefinitions?.find(x => x.scheduleTypeId === scheduleTypeIdWatch && x.clinicLocationId === value)?.id ?? '';
    setValue('scheduleDefinitionId', scheduleDefinitionId);
  };
  const clinicLocationId = useField<string>(
    fieldArguments('clinicLocationId', 'Clinic Location', undefined, 'Clinic Location is required'),
  );
  const clinicLocationIdWatch = watch('clinicLocationId');
  clinicLocationId.setValue = clinicLocationIdSetValue;
  // Clinic Location - End

  // Schedule Type - Start
  const scheduleTypeIdSetValue = (value: string) => {
    setValue('scheduleTypeId', value);
    const scheduleDefinitionId =
      scheduleDefinitions?.find(x => x.scheduleTypeId === value && x.clinicLocationId === clinicLocationIdWatch)?.id ?? '';
    setValue('scheduleDefinitionId', scheduleDefinitionId);
  };
  const scheduleTypeId = useField<string>(
    fieldArguments('scheduleTypeId', 'Schedule Type', undefined, 'Schedule Type is required'),
  );
  const scheduleTypeIdWatch = watch('scheduleTypeId');
  scheduleTypeId.setValue = scheduleTypeIdSetValue;
  // Schedule Type - End

  const patientId = useField<string>(fieldArguments('patientId', 'Patient', undefined, 'Patient is required'));

  const appointmentTypeId = useField<string>(
    fieldArguments('appointmentTypeId', 'Appt Type', undefined, 'Appointment Type is required'),
  );
  const appointmentTypeIdWatch = watch('appointmentTypeId');

  const appointmentStatusId = useField<string>(
    fieldArguments('appointmentStatusId', 'Appt Status', undefined, 'Appointment Status is required'),
  );
  const appointmentStatusIdWatch = watch('appointmentStatusId');

  const durationMinutes = useField<number>(
    fieldArguments('durationMinutes', 'Duration (Minutes)', undefined, 'Duration is required'),
  );

  const additionalInfo = useField<string>(fieldArguments('additionalInfo', 'Additional Info'));
  const appointmentNote = useField<string>(fieldArguments('appointmentNote', 'Appointment Note'));

  // These are solely for handling validation
  const patientPhone1 = useField<string>(fieldArguments('patientPhone1', ''));
  const patientClinicLocationId = useField<string>(fieldArguments('patientClinicLocationId', ''));

  const patientGlobalId = useField<string>(fieldArguments('globalId', 'Global ID'));

  useEffect(() => {
    // We want to update the schedule definition when the schedule definition id changes
    if (scheduleDefinitionIdWatch !== scheduleDefinition?.id) {
      const newScheduleDefinition = scheduleDefinitionFromId(scheduleDefinitionIdWatch);
      setScheduleDefinition(newScheduleDefinition);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scheduleDefinitionIdWatch, scheduleDefinitionFromId]);

  useEffect(() => {
    timeField.clearErrors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeWatch]);

  useEffect(() => {
    dateField.clearErrors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateWatch]);

  useEffect(() => {
    // we want to clear errors if any of these values change
    clinicLocationId.clearErrors();
    scheduleTypeId.clearErrors();
    scheduleDefinitionId.clearErrors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clinicLocationIdWatch, scheduleTypeIdWatch, scheduleDefinitionIdWatch]);

  useEffect(() => {
    appointmentStatusId.clearErrors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointmentTypeIdWatch]);

  const [prevAppointmentTypeId, setPrevAppointmentTypeId] = useState(appointmentTypeIdWatch);

  useEffect(() => {
    if (appointmentTypeIdWatch === prevAppointmentTypeId) {
      return;
    }
    setPrevAppointmentTypeId(appointmentTypeIdWatch);
    const duration = appointmentTypeFromId(appointmentTypeIdWatch ?? '')?.durationMinutes;
    durationMinutes.setValue(duration ?? defaultDuration);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointmentTypeIdWatch]);

  useEffect(() => {
    setValue('globalId', patient?.globalId ?? '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patient]);

  const [additionalAppointmentIndexFocus, setAdditionalAppointmentIndexFocus] = useState<number | null>(null);
  const [additionalAppointmentIndexLock, setAdditionalAppointmentIndexLock] = useState<number | null>(null);

  const [focused, setFocused] = useState(
    Focuser.Build(watch, setValue, additionalAppointmentIndexFocus, additionalAppointmentIndexLock),
  );

  useEffect(() => {
    const focuser = Focuser.Build(watch, setValue, additionalAppointmentIndexFocus, additionalAppointmentIndexLock);
    setFocused(focuser);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [additionalAppointmentIndexFocus, additionalAppointmentIndexLock]);

  const [additionalAppointmentCount, setAdditionalAppointmentCount] = useState<number>(0);
  const additionalAppointmentWatch = watch('additionalAppointments');

  useEffect(() => {
    setAdditionalAppointmentCount(additionalAppointmentWatch?.length ?? 0);
  }, [additionalAppointmentWatch]);

  return (
    <>
      <AppointmentFormContext.Provider
        value={{
          timeField,
          timeWatch: focused.getTimeWatcher(),
          timeSetValue: focused.getTimeSetter(),
          dateField,
          dateWatch: focused.getDateWatcher(),
          dateSetValue: focused.getDateSetter(),
          patient,
          setPatient,
          scheduleDefinitionId,
          scheduleDefinitionIdWatch,
          setScheduleDefinitionId: val => scheduleDefinitionId.setValue(val ?? ''),
          appointmentId,
          appointmentIdWatch,
          scheduleDefinition,
          clinicLocationId,
          clinicLocationIdWatch,
          setClinicLocationId: val => clinicLocationId.setValue(val ?? ''),
          scheduleTypeId,
          scheduleTypeIdWatch,
          setScheduleTypeId: val => scheduleTypeId.setValue(val ?? ''),
          patientId,
          appointmentTypeId,
          appointmentTypeIdWatch,
          appointmentStatusId,
          appointmentStatusIdWatch,
          handleSubmit,
          setError,
          durationMinutes,
          appointmentNote,
          additionalInfo,
          patientGlobalId,
          register,
          watch,
          setValue,
          control,
          errors,
          clearErrors,
          getValues,
          additionalAppointmentIndexFocus,
          setAdditionalAppointmentIndexFocus,
          additionalAppointmentIndexLock,
          setAdditionalAppointmentIndexLock,
          sourceDate: watch(`appointmentDate`),
          sourceTime: watch(`appointmentTime`),
          patientPhone1,
          patientClinicLocationId,
          additionalAppointmentCount,
        }}
      >
        {children}
      </AppointmentFormContext.Provider>
    </>
  );
}

export function useAppointmentFormContext() {
  return useContext(AppointmentFormContext);
}
