import { createContext, useContext, useEffect, useRef, useState } from 'react';
import React from 'react';
import { useMicrophoneStream } from './microphone-recorder';
import { EventStreamMarshaller } from '@aws-sdk/eventstream-marshaller';
import { fromUtf8, toUtf8 } from '@aws-sdk/util-utf8-node';

export interface IWebsocketTranscriptionContext {
  lastTranscriptionMessage: string | null;
  startTranscript: (websocketUrl: string) => Promise<void>;
  stopTranscript: () => void;
  isTranscribing: boolean;
}

const WebsocketTranscriptionContext = createContext<IWebsocketTranscriptionContext>({
  lastTranscriptionMessage: null,
  startTranscript: async () => {},
  stopTranscript: () => {},
  isTranscribing: false,
});

export type WebSocketMessage = {};

export function WebsocketTranscriptionProvider({ children }) {
  const [lastTranscriptionMessage, setLastTranscriptionMessage] = useState<string | null>(null);
  const { startRecording, stopRecording, lastChunk } = useMicrophoneStream();
  const [isTranscribing, setIsTranscribing] = useState<boolean>(false);

  const eventStreamMarshaller = new EventStreamMarshaller(toUtf8, fromUtf8); // an object for marshalling and unmarshalling audio data

  // let socket: WebSocket;
  // socket should be a reference type so that we can access it in the useEffect
  let socket = useRef<WebSocket>(null);

  const startTranscript = async (presignedUrl: string) => {
    // @ts-ignore
    socket.current = new WebSocket(presignedUrl);
    socket.current.binaryType = 'arraybuffer';

    socket.current.onopen = async () => {
      await startRecording();
      console.log('WebSocket connection is open.');
      setIsTranscribing(true);
    };

    socket.current.onmessage = function (message) {
      // @ts-ignore
      let messageWrapper = eventStreamMarshaller.unmarshall(Buffer(message.data));
      // @ts-ignore
      let messageBody = JSON.parse(String.fromCharCode.apply(String, messageWrapper.body));

      console.log('Received data from websocket');

      if (messageWrapper.headers[':message-type'].value === 'event') {
        let results = messageBody.Transcript?.Results;
        if (results.length && !results[0]?.IsPartial) {
          const newTranscript = results[0].Alternatives[0].Transcript;
          setLastTranscriptionMessage(newTranscript);
        }
      }
    };

    socket.current.onerror = function (error) {
      console.log('WebSocket connection error. Try again.', error);
    };
  };

  const stopTranscript = () => {
    if (socket.current) {
      socket.current.close();
    }
    stopRecording();
    setIsTranscribing(false);
  };

  // This is basically the chunkin pipeline. We're getting some audio chunks from the microphone-recorder and we're going to send them to the websocket.
  useEffect(() => {
    if (lastChunk) {
      if (socket?.current?.readyState === WebSocket.OPEN) {
        console.log('Sending to websocket');
        // @ts-ignore
        socket.current.send(lastChunk);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastChunk]);

  return (
    <WebsocketTranscriptionContext.Provider
      value={{
        lastTranscriptionMessage,
        startTranscript,
        stopTranscript,
        isTranscribing,
      }}
    >
      <>{children}</>
    </WebsocketTranscriptionContext.Provider>
  );
}

export const useWebsocketTranscription = () => {
  return useContext(WebsocketTranscriptionContext);
};
