import { createContext, useContext, useRef, useState } from 'react';
import React from 'react';
import MicrophoneStream from 'microphone-stream';
import { EventStreamMarshaller, Message } from '@aws-sdk/eventstream-marshaller';
import { fromUtf8, toUtf8 } from '@aws-sdk/util-utf8-node';

export interface IMicrophoneStreamContext {
  isRecording: boolean;
  startRecording: () => Promise<void>;
  stopRecording: () => void;
  lastChunk: Uint8Array | undefined | null;
}

const MicrophoneStreamContext = createContext<IMicrophoneStreamContext>({
  isRecording: false,
  startRecording: async () => {},
  stopRecording: () => {},
  lastChunk: null,
});

const pcmEncode = input => {
  var offset = 0;
  var buffer = new ArrayBuffer(input.length * 2);
  var view = new DataView(buffer);
  for (var i = 0; i < input.length; i++, offset += 2) {
    var s = Math.max(-1, Math.min(1, input[i]));
    view.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7fff, true);
  }
  return buffer;
};

const downsampleBuffer = (buffer, inputSampleRate = SAMPLE_RATE, outputSampleRate = 16000) => {
  if (outputSampleRate === inputSampleRate) {
    return buffer;
  }

  var sampleRateRatio = inputSampleRate / outputSampleRate;
  var newLength = Math.round(buffer.length / sampleRateRatio);
  var result = new Float32Array(newLength);
  var offsetResult = 0;
  var offsetBuffer = 0;

  while (offsetResult < result.length) {
    var nextOffsetBuffer = Math.round((offsetResult + 1) * sampleRateRatio);

    var accum = 0,
      count = 0;

    for (var i = offsetBuffer; i < nextOffsetBuffer && i < buffer.length; i++) {
      accum += buffer[i];
      count++;
    }

    result[offsetResult] = accum / count;
    offsetResult++;
    offsetBuffer = nextOffsetBuffer;
  }

  return result;
};

const getAudioEventMessage: (buffer) => Message = buffer => {
  return {
    headers: {
      ':message-type': {
        type: 'string',
        value: 'event',
      },
      ':event-type': {
        type: 'string',
        value: 'AudioEvent',
      },
    },
    body: buffer,
  };
};

const SAMPLE_RATE = 44100;

export function MicrophoneStreamProvider({ children }) {
  const [isRecording, setIsRecording] = useState(false);
  const [lastChunk, setLastChunk] = useState<Uint8Array | undefined | null>(null);

  const inputSampleRate = useRef<number>(null);
  const sampleRate = SAMPLE_RATE;
  const microphoneStream = useRef<MicrophoneStream>(null);
  const eventStreamMarshaller = useRef(new EventStreamMarshaller(toUtf8, fromUtf8));

  const convertAudioToBinaryMessage = audioChunk => {
    let raw = MicrophoneStream.toRaw(audioChunk);

    if (raw == null) return;

    let downsampledBuffer = downsampleBuffer(raw, inputSampleRate.current!, sampleRate);
    let pcmEncodedBuffer = pcmEncode(downsampledBuffer);

    let audioEventMessage = getAudioEventMessage(Buffer.from(pcmEncodedBuffer));

    const binary = eventStreamMarshaller.current.marshall(audioEventMessage);

    return binary;
  };

  const processChunks = (binary: Uint8Array | undefined) => {
    setLastChunk(binary);
  };

  const createMicrophoneStream = async () => {
    // @ts-ignore
    microphoneStream.current = new MicrophoneStream();
    // @ts-ignore
    microphoneStream.current.on('format', data => {
      //@ts-ignore
      inputSampleRate.current = data.sampleRate;
    });
    microphoneStream.current.setStream(
      await window.navigator.mediaDevices.getUserMedia({
        video: false,
        audio: true,
      }),
    );
  };

  const startRecording = async () => {
    if (microphoneStream.current) {
      stopRecording();
    }

    // @ts-ignore
    await createMicrophoneStream();

    // @ts-ignore
    microphoneStream.current!.on('data', function (rawAudioChunk) {
      let binary = convertAudioToBinaryMessage(rawAudioChunk);
      processChunks(binary);
    });

    setIsRecording(true);
  };

  const stopRecording = () => {
    if (microphoneStream.current) {
      console.log('Recording stopped');
      microphoneStream.current.stop();
      // @ts-ignore
      microphoneStream.current.destroy();
      // @ts-ignore
      microphoneStream.current = undefined;
    }
    setIsRecording(false);
  };

  return (
    <MicrophoneStreamContext.Provider
      value={{
        isRecording,
        startRecording,
        stopRecording,
        lastChunk,
      }}
    >
      <>{children}</>
    </MicrophoneStreamContext.Provider>
  );
}

export const useMicrophoneStream = () => {
  return useContext(MicrophoneStreamContext);
};
