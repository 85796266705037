import { faPlus, faPrint } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Patient, useGetPatientAppointments } from 'app/api/PatientApi';
import { ButtonLink } from 'app/components/Buttons/ButtonLink';
import { format } from 'date-fns';
import React, { ChangeEvent, useId, useMemo, useRef, useState } from 'react';
import { appointmentFilter, pastAppointmentCount } from './appointmentListPrintable';
import { AppointmentListTable } from './AppointmentListTable';
import { AppointmentModalNew } from 'app/components/Schedule/layout/AppointmentModalNew';
import { Appointment } from '../../api/AppointmentApi';
import { useProfile } from '../../../utils/profile';
import { LoadingWrapper } from '../../../utils/loadingWrapper';

export type PatientAppointmentListParams = {
  patient: Patient;
  showPrintButton: boolean;
  showNewAppointmentButton: boolean;
  enableEditing: boolean;
  onGoToSchedule: (appointment: Appointment) => void;
  initialAppointmentId?: string;
  tableContainerClass: string;
  onAppointmentDetailClicked?: (appointmentId: string) => void;
};

export const PatientAppointmentList = ({
  patient,
  showPrintButton,
  showNewAppointmentButton: _showNewAppointmentButton,
  enableEditing,
  onGoToSchedule: _onGoToSchedule,
  initialAppointmentId,
  tableContainerClass,
  onAppointmentDetailClicked,
}: PatientAppointmentListParams) => {
  // not working... no plans to fix.
  const showNewAppointmentButton = _showNewAppointmentButton;

  const [date] = useState<string>(() => {
    const date = new Date();
    return format(date, 'yyyy-MM-dd');
  });

  const { data: appointments, isLoading, error } = useGetPatientAppointments(patient.id);

  const [time] = useState<string>(() => {
    const now = new Date();
    const hours = now.getHours();
    const minutes = Math.floor(now.getMinutes() / 30) * 30;
    const t = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    return t;
  });

  const id = useId();

  const [showPastAppointments, setShowPastAppointments] = useState(true);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setShowPastAppointments(e.target.checked);
  };

  const filteredAppointments = useMemo(() => {
    return appointments?.filter(appointment => appointmentFilter(appointment, showPastAppointments)) ?? [];
  }, [appointments, showPastAppointments]);

  const [newModalKey, setNewModalKey] = useState(0);
  const [newAppointmentModalIsOpen, _setNewAppointmentModalIsOpen] = useState(false);
  const setNewAppointmentModalIsOpen = (val: boolean) => {
    if (!val) {
      setNewModalKey(newModalKey + 1);
    }
    _setNewAppointmentModalIsOpen(val);
  };

  let displayName = patient.displayLong;

  const containerRef = useRef<HTMLDivElement>(null);

  const onGoToSchedule: (appointment: Appointment) => void = appointment => {
    setNewAppointmentModalIsOpen(false);
    _onGoToSchedule(appointment);
  };

  const noop = useMemo(() => () => {}, []);

  const openAppointmentListPdf = async () => {
    const params = new URLSearchParams({
      patientId: patient.id,
      includeColors: 'true',
      includeNotes: 'true',
    });

    window.open(`/pdf/appointments?${params}`, '_blank')?.focus();
  };

  const profile = useProfile();

  const showNewPdfButton = profile.capabilities.canViewNewPdfButton();

  return (
    <>
      {newAppointmentModalIsOpen && (
        <AppointmentModalNew
          key={newModalKey}
          isOpen={newAppointmentModalIsOpen}
          closeModal={() => setNewAppointmentModalIsOpen(false)}
          time={time}
          date={date}
          patient={patient}
          scheduleDefinitionId={null}
          hasAppointmentsProvider={false}
          onAppointmentSubmitSuccess={noop}
        />
      )}

      <div>
        <div className="inline-flex justify-between">
          <h1 className="text-2xl font-semibold text-indigo-900 ">Appointments for {displayName}</h1>
          {appointments && pastAppointmentCount(appointments) > 0 && (
            <div className="relative flex items-start my-1 ml-4 print:hidden">
              <div className="flex h-5 items-center">
                <input
                  id={id}
                  type="checkbox"
                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                  checked={showPastAppointments}
                  onChange={onChange}
                  autoComplete="off"
                />
              </div>
              <div className="ml-3 text-sm">
                <label htmlFor={id} className="font-medium text-gray-700">
                  Show Past Appointments
                </label>
              </div>
            </div>
          )}
          {showPrintButton && (
            <span className="ml-2">
              {showNewPdfButton && (
                <button type="button" onClick={openAppointmentListPdf}>
                  <ButtonLink
                    to={`/patients/${patient.id}/appointments/pdf`}
                    label="Printer Friendly"
                    icon={faPrint}
                    newTab={true}
                  />
                  Print
                </button>
              )}
            </span>
          )}
        </div>
      </div>

      {showNewAppointmentButton && (
        <button
          data-testid="new-appointment-button"
          type="button"
          onClick={() => setNewAppointmentModalIsOpen(true)}
          className="mt-2 inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          New Appointment
          <FontAwesomeIcon className="ml-3 -mr-1 h-5 w-5" icon={faPlus} aria-hidden="true" />
        </button>
      )}

      <LoadingWrapper isLoading={isLoading} error={error}>
        <>
          {appointments && appointments.length === 0 && <div className="italic">No appointments yet</div>}
          {appointments && !showPastAppointments && pastAppointmentCount(appointments) > 0 && (
            <div className="italic">{pastAppointmentCount(appointments)} past appointments not shown</div>
          )}
          <div ref={containerRef}>
            {!!filteredAppointments?.length && (
              <AppointmentListTable
                appointments={filteredAppointments}
                enableEditing={enableEditing}
                containerRef={containerRef}
                renderPrintLayout={false}
                onGoToSchedule={onGoToSchedule}
                initialAppointmentId={initialAppointmentId}
                tableContainerClass={tableContainerClass}
                onAppointmentDetailClicked={onAppointmentDetailClicked}
              />
            )}
          </div>
        </>
      </LoadingWrapper>
    </>
  );
};
