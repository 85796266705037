import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { ChangeEventHandler, forwardRef, MouseEventHandler, useEffect, useId, useMemo } from 'react';
import { GenericInputGroupProps } from './InputGroup';
import { InfoTip } from '../Tooltip';

export type Glyph = {
  value: string;
  label?: string;
};

export type TextAreaGroupProps = GenericInputGroupProps<HTMLTextAreaElement, string> & {
  placeholder: string;
  rows?: number;
  glyphs?: Glyph[];
  focusHandler?: () => void;
  cursorPosition?: number;
  setCursorPosition?: (cursorPosition: number) => void;
};

export const TextAreaGroup = forwardRef<HTMLTextAreaElement, TextAreaGroupProps>(
  (
    {
      label,
      placeholder,
      glyphs,
      rows,
      name,
      errors,
      onBlur,
      clearErrors,
      setValue,
      readonly,
      tooltip,
      bgColor,
      focusHandler,
      cursorPosition,
      setCursorPosition,
    },
    ref,
  ) => {
    var baseClass = classNames(
      'block w-full rounded-md border-gray-300 pr-10 text-gray-900 placeholder-gray-300 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm',
      !!bgColor ? `bg-${bgColor}` : 'bg-white',
    );

    var invalidClass = classNames(
      baseClass,
      'border-red-300 text-red-900 placeholder-red-300 focus:border-red-500 focus:ring-red-500',
    );

    const id = useId();

    const handleCursorChange = () => {
      const textArea = document.getElementById(id) as HTMLTextAreaElement;
      const cursorPosition = textArea.selectionStart;
      if (setCursorPosition) {
        setCursorPosition(cursorPosition);
      }
    };

    const onChange: ChangeEventHandler<HTMLTextAreaElement> = event => {
      handleCursorChange();
      clearErrors();
      setValue(event.target.value);
    };

    const onClick: MouseEventHandler<HTMLTextAreaElement> = () => {
      handleCursorChange();
    };

    const onKeyUp = () => {
      handleCursorChange();
    };

    const iterableErrorMessages = useMemo(() => {
      if (errors?.message) {
        if (Array.isArray(errors.message)) {
          return errors.message;
        }
        return [errors.message];
      }
      return [];
    }, [errors]);

    const onGlyphClick = (glyph: Glyph) => {
      const textArea = document.getElementById(id) as HTMLTextAreaElement;
      const cursorPosition = textArea.selectionStart;
      var val = glyph.value;

      // is previous position a space? if not, add a space
      if (cursorPosition > 0 && textArea.value[cursorPosition - 1] !== ' ') {
        val = ' ' + val;
      }

      // is next position a space? if not, add a space
      if (cursorPosition < textArea.value.length && textArea.value[cursorPosition] !== ' ') {
        val = val + ' ';
      }

      const newValue = textArea.value.slice(0, cursorPosition) + val + textArea.value.slice(cursorPosition);
      setValue(newValue);
    };

    useEffect(() => {
      if (cursorPosition) {
        const textArea = document.getElementById(id) as HTMLTextAreaElement;
        textArea.focus();
        textArea.setSelectionRange(cursorPosition, cursorPosition);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cursorPosition]);

    return (
      <>
        <label htmlFor={id} className="block text-sm font-medium text-gray-700">
          {label}
          {tooltip && <InfoTip content={tooltip} />}
        </label>
        <div className="relative mt-1 rounded-md shadow-sm">
          <textarea
            onChange={onChange}
            onClick={onClick}
            onKeyUp={onKeyUp}
            onBlur={onBlur}
            name={name}
            ref={ref}
            id={id}
            className={errors ? invalidClass : baseClass}
            placeholder={placeholder}
            {...(errors && { 'aria-invalid': 'true' })}
            {...(errors && { 'aria-describedby': `${id}-error` })}
            rows={rows}
            autoComplete="off"
            readOnly={readonly}
            onFocus={focusHandler}
          />
          {errors && (
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <FontAwesomeIcon className="h-5 w-5 text-red-500" icon={faTriangleExclamation} aria-hidden="true" />
            </div>
          )}
        </div>
        <div>
          {glyphs?.map(g => (
            <button type="button" key={g.value} className="mx-1" onClick={() => onGlyphClick(g)}>
              {g.value}
            </button>
          ))}
        </div>
        {iterableErrorMessages.map((message, index) => (
          <p key={index} className="mt-2 text-sm text-red-600" id={`${id}-error`}>
            {message}
          </p>
        ))}
      </>
    );
  },
);
