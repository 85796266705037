import React, { useMemo } from 'react';
import classNames from 'classnames';
import { faArrowsRotate, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '../Tooltip';

export const Button = ({
  onClick,
  children,
  className,
  disabled,
  type = 'button',
  size: _size = 'regular',
  color: _color = 'primary',
  icon,
  active,
  tooltip,
}: {
  onClick: () => void;
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  active?: boolean;
  icon?: IconDefinition;
  type?: 'button' | 'submit' | 'reset';
  size?: 'xs' | 'sm' | 'regular' | 'lg' | 'xl';
  color?: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark';
  tooltip?: string;
}) => {
  const getBackgroundColor = (color: string) => {
    switch (color) {
      case 'primary':
        return 'bg-indigo-600 hover:bg-indigo-500 focus-visible:outline-indigo-600';
      case 'secondary':
        return 'bg-gray-600 hover:bg-gray-500 focus-visible:outline-gray-600';
      case 'success':
        return 'bg-green-600 hover:bg-green-500 focus-visible:outline-green-600';
      case 'danger':
        return 'bg-red-600 hover:bg-red-500 focus-visible:outline-red-600';
      case 'warning':
        return 'bg-yellow-600 hover:bg-yellow-500 focus-visible:outline-yellow-600';
      case 'info':
        return 'bg-blue-600 hover:bg-blue-500 focus-visible:outline-blue-600';
      case 'light':
        return 'bg-gray-100 hover:bg-gray-200 focus-visible:outline-gray-100';
      case 'dark':
        return 'bg-gray-800 hover:bg-gray-700 focus-visible:outline-gray-800';
      default:
        return 'bg-indigo-600 hover:bg-indigo-500 focus-visible:outline-indigo-600';
    }
  };

  const getTextColor = (color: string) => {
    switch (color) {
      case 'primary':
        return 'text-white';
      case 'secondary':
        return 'text-white';
      case 'success':
        return 'text-white';
      case 'danger':
        return 'text-white';
      case 'warning':
        return 'text-white';
      case 'info':
        return 'text-white';
      case 'light':
        return 'text-gray-900';
      case 'dark':
        return 'text-white';
      default:
        return 'text-white';
    }
  };

  const getPadding = (size: string) => {
    switch (size) {
      case 'xs':
        return 'px-2 py-1 gap-x-1.5';
      case 'sm':
        return 'px-2 py-1 gap-x-1.5';
      case 'regular':
        return 'px-2.5 py-1.5 gap-x-1.5';
      case 'lg':
        return 'px-3 py-2 gap-x-2';
      case 'xl':
        return 'px-3.5 py-2.5 gap-x-2';
      default:
        return 'px-2.5 py-1.5';
    }
  };

  const getFontSize = (size: string) => {
    switch (size) {
      case 'xs':
        return 'text-xs';
      default:
        return 'text-sm';
    }
  };

  const backgroundColor = useMemo(() => getBackgroundColor(_color), [_color]);
  const textColor = useMemo(() => getTextColor(_color), [_color]);
  const padding = useMemo(() => getPadding(_size), [_size]);
  const fontSize = useMemo(() => getFontSize(_size), [_size]);

  const buttonClass = classNames(
    'inline-flex justify-center rounded-md text-sm font-medium shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2',
    padding,
    backgroundColor,
    textColor,
    fontSize,
    disabled || active ? 'opacity-50 cursor-not-allowed' : '',
  );

  return (
    <Tooltip content={tooltip ?? ''}>
      <button type={type} onClick={onClick} className={buttonClass} disabled={disabled}>
        {icon && (
          <FontAwesomeIcon
            className={classNames('-ml-1 h-5 w-5', active ? 'animate-spin' : '')}
            icon={active ? faArrowsRotate : icon}
            aria-hidden="true"
          />
        )}
        {children}
      </button>
    </Tooltip>
  );
};
