import { LoadingAnimated } from 'app/components/LoadingAnimated';
import { PageContainer } from 'app/components/PageContainer';
import * as React from 'react';
import { format } from 'date-fns';

export function KitchenSink() {
  const today = format(new Date(), 'yyyy-MM-dd');
  const thisYear = parseInt(today.slice(0, 4));
  const december25 = format(new Date(thisYear, 11, 25), 'yyyy-MM-dd');
  const november25 = format(new Date(thisYear, 10, 25), 'yyyy-MM-dd');

  return (
    <PageContainer title={`Kitchen Sink`} description="Assorted Components">
      Welcome to the Kitchen Sink
      <div className="divide-y divide-gray-200">
        <div className="mt-8">
          <span className="gray-900 italic">Loading Indicator:</span>
          <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-12">
            <div className="sm:col-span-3">
              Today
              <LoadingAnimated date={today} />
            </div>
            <div className="sm:col-span-3">
              November 25, {thisYear}
              <LoadingAnimated date={november25} />
            </div>
            <div className="sm:col-span-3">
              December 25, {thisYear}
              <LoadingAnimated date={december25} />
            </div>
          </div>
        </div>
      </div>
    </PageContainer>
  );
}
