import {
  Column,
  ColumnFiltersState,
  Table,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { Appointment } from 'app/api/AppointmentApi';
import { AppointmentModalEdit } from 'app/components/Schedule/layout/AppointmentModalEdit';
import { format } from 'date-fns';
import React, { MouseEvent, useMemo, useState } from 'react';
import { useFlexworxConfig } from 'utils/flexworx-config';
import { useProfile } from '../../../utils/profile';
import { AppointmentListContextMenu } from './AppointmentListContextMenu';
import { AppointmentListRow } from './AppointmentListRow';
import { AppointmentListNotes } from './AppointmentListNotes';
import classNames from 'classnames';
import { faBriefcaseMedical } from '@fortawesome/free-solid-svg-icons';
import { Button } from '../../components/Buttons/Button';

const noop = () => {};

function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}: {
  value: string | number;
  onChange: (value: string | number) => void;
  debounce?: number;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) {
  const [value, setValue] = React.useState(initialValue);

  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return <input {...props} value={value} onChange={e => setValue(e.target.value)} />;
}

export function Filter({ column, table }: { column: Column<any, unknown>; table: Table<any> }) {
  const firstValue = table.getPreFilteredRowModel().flatRows[0]?.getValue(column.id);

  const columnFilterValue = column.getFilterValue();

  const sortedUniqueValues = React.useMemo(
    () => (typeof firstValue === 'number' ? [] : Array.from(column.getFacetedUniqueValues().keys()).sort()),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [column.getFacetedUniqueValues()],
  );

  return typeof firstValue === 'number' ? (
    <div className="print:hidden">
      <div className="flex space-x-2">
        <DebouncedInput
          type="number"
          min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
          max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
          value={(columnFilterValue as [number, number])?.[0] ?? ''}
          onChange={value => column.setFilterValue((old: [number, number]) => [value, old?.[1]])}
          placeholder={`Min ${column.getFacetedMinMaxValues()?.[0] ? `(${column.getFacetedMinMaxValues()?.[0]})` : ''}`}
          className="w-24 border shadow rounded"
        />
        <DebouncedInput
          type="number"
          min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
          max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
          value={(columnFilterValue as [number, number])?.[1] ?? ''}
          onChange={value => column.setFilterValue((old: [number, number]) => [old?.[0], value])}
          placeholder={`Max ${column.getFacetedMinMaxValues()?.[1] ? `(${column.getFacetedMinMaxValues()?.[1]})` : ''}`}
          className="w-24 border shadow rounded"
        />
      </div>
      <div className="h-1" />
    </div>
  ) : (
    <div className="print:hidden">
      <datalist id={column.id + 'list'}>
        {sortedUniqueValues.slice(0, 5000).map((value: any) => (
          <option value={value} key={value} />
        ))}
      </datalist>
      <DebouncedInput
        type="text"
        value={(columnFilterValue ?? '') as string}
        onChange={value => column.setFilterValue(value)}
        placeholder={`Search...`}
        className="w-full border shadow rounded"
        list={column.id + 'list'}
      />
      <div className="h-1" />
    </div>
  );
}

export type AppointmentListTableParams<T extends HTMLElement = HTMLElement> = {
  appointments: Appointment[];
  enableEditing?: boolean;
  containerRef: React.RefObject<T>;
  renderPrintLayout: boolean;
  onGoToSchedule: (appointment: Appointment) => void;
  initialAppointmentId?: string;
  tableContainerClass: string;
  onAppointmentDetailClicked?: (appointmentId: string) => void;
};

const initialContextMenu = {
  show: false,
  x: 0,
  y: 0,
};

export const AppointmentListTable = ({
  appointments: _appointments,
  enableEditing,
  containerRef,
  renderPrintLayout,
  onGoToSchedule,
  initialAppointmentId,
  tableContainerClass,
  onAppointmentDetailClicked,
}: AppointmentListTableParams) => {
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([]);
  const profile = useProfile()!;

  const appointments = useMemo(() => {
    return _appointments?.sort((a, b) => {
      // sort by date+time
      const dateA = a.appointmentDate + a.appointmentTime;
      const dateB = b.appointmentDate + b.appointmentTime;
      if (dateA < dateB) {
        return -1;
      }
      if (dateA > dateB) {
        return 1;
      }
      return 0;
    });
  }, [_appointments]);

  const columnHelper = createColumnHelper<Appointment>();

  const {
    clinicLocationFromScheduleDefinitionId,
    scheduleTypeFromScheduleDefinitionId,
    appointmentTypeFromId,
    appointmentStatusFromId,
  } = useFlexworxConfig();

  const columns = [
    columnHelper.accessor(row => row.appointmentDate, {
      id: 'appointmentDate',
      cell: info => {
        const date = new Date(
          parseInt(info.getValue().split('-')[0]),
          parseInt(info.getValue().split('-')[1]) - 1,
          parseInt(info.getValue().split('-')[2]),
        );

        const formattedDate = format(date, 'MMM d, yyyy');
        const dayOfWeek = format(date, 'EEEE');
        return (
          <>
            {formattedDate}&nbsp;
            <span className="text-sm">{dayOfWeek}</span>
          </>
        );
      },
      header: () => <span>Date</span>,
      enableColumnFilter: false,
    }),
    columnHelper.accessor(row => row.appointmentTime, {
      id: 'appointmentTime',
      cell: info => {
        const time = info.getValue();
        const formattedTime = format(new Date(`2020-01-01T${time}`), 'h:mm a');
        return <span>{formattedTime}</span>;
      },
      header: () => <span>Time</span>,
      enableColumnFilter: false,
    }),
    columnHelper.accessor(row => appointmentTypeFromId(row.appointmentTypeId)?.name, {
      id: 'appointmentType',
      cell: info => info.getValue(),
      header: () => <span>Description</span>,
      enableColumnFilter: false,
    }),
    columnHelper.accessor(row => appointmentStatusFromId(row.appointmentStatusId)?.name, {
      id: 'appointmentStatus',
      cell: info => info.getValue(),
      header: () => <span>Status</span>,
      enableColumnFilter: false,
    }),
  ];

  if (!renderPrintLayout) {
    columns.push(
      columnHelper.accessor(row => scheduleTypeFromScheduleDefinitionId(row.scheduleDefinitionId)?.name, {
        id: 'scheduleType',
        cell: info => info.getValue(),
        header: () => <span>Schedule Type</span>,
        filterFn: (rows: any, id: string, filterValue: string) => {
          if (filterValue === '') {
            return true;
          }
          let scheduleType: string;
          scheduleType = rows.getValue(id);
          return scheduleType.toLowerCase().includes(filterValue.toLowerCase());
        },
        enableHiding: true,
      }),
    );
  }

  columns.push(
    columnHelper.accessor(row => clinicLocationFromScheduleDefinitionId(row.scheduleDefinitionId)?.name, {
      id: 'clinicLocation',
      cell: info => info.getValue(),
      header: () => <span>Clinic Location</span>,
      filterFn: (rows: any, id: string, filterValue: string) => {
        if (filterValue === '') {
          return true;
        }
        let clinicLocation: string;
        clinicLocation = rows.getValue(id);
        return clinicLocation.toLowerCase().includes(filterValue.toLowerCase());
      },
    }),
  );

  if (!!onAppointmentDetailClicked && profile.capabilities.canViewAppointmentDetail()) {
    columns.push(
      columnHelper.accessor(row => row.id, {
        id: 'detail',
        cell: info => (
          <>
            <div>
              <Button
                color="info"
                size="sm"
                icon={faBriefcaseMedical}
                onClick={() => onAppointmentDetailClicked(info.getValue())}
                tooltip="View more details about this appointment, including dictation."
              >
                More
              </Button>
            </div>
          </>
        ),
        header: () => <></>,
        enableColumnFilter: false,
      }),
    );
  }

  const table = useReactTable({
    data: appointments ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      columnFilters,
    },
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(),
  });

  // active appointment is used by the modal to determine which appointment to edit & which appointment summary to show at bottom of table.
  const [activeAppointment, setActiveAppointment] = React.useState<Appointment | null>(() => {
    if (!initialAppointmentId) {
      return null;
    }
    return appointments?.find(appointment => appointment.id === initialAppointmentId) ?? null;
  });

  const [editAppointmentModalIsOpen, _setEditAppointmentModalIsOpen] = React.useState(false);
  const [editModalKey, setEditModalKey] = React.useState(0);
  const setEditAppointmentModalIsOpen = (val: boolean) => {
    if (!val) {
      setEditModalKey(editModalKey + 1);
    }
    _setEditAppointmentModalIsOpen(val);
  };

  const onDoubleClick = (e: MouseEvent<HTMLTableRowElement, globalThis.MouseEvent>, appointment: Appointment) => {
    if (!enableEditing) {
      return;
    }
    setActiveAppointment(appointment);
    setEditAppointmentModalIsOpen(true);
  };

  const onSingleClick = (e: MouseEvent<HTMLTableRowElement, globalThis.MouseEvent>, appointment: Appointment) => {
    setActiveAppointment(appointment);
  };

  const [rightClickEnabled, setRightClickEnabled] = useState(true);
  const toggleRightClick = () => {
    setRightClickEnabled(!rightClickEnabled);
  };

  const [contextMenu, setContextMenu] = React.useState(initialContextMenu);
  const [contextAppointment, setContextAppointment] = useState<Appointment>();
  const onRightClick = (e: MouseEvent<HTMLTableRowElement, globalThis.MouseEvent>, appointment: Appointment) => {
    if (!rightClickEnabled) {
      return;
    }
    e.preventDefault();
    setContextAppointment(appointment);
    setContextMenu({ show: true, x: e.pageX, y: e.pageY });
  };

  const contextMenuClose = () => {
    // setActiveBucket(null);
    setContextMenu({
      show: false,
      x: 0,
      y: 0,
    });
  };

  if (appointments && appointments.length > 0) {
    return (
      <>
        {contextMenu.show && (
          <AppointmentListContextMenu
            x={contextMenu.x}
            y={contextMenu.y}
            appointment={contextAppointment!}
            close={contextMenuClose}
            container={containerRef}
          />
        )}
        <AppointmentModalEdit
          key={editModalKey}
          isOpen={editAppointmentModalIsOpen}
          closeModal={() => setEditAppointmentModalIsOpen(false)}
          appointmentId={activeAppointment?.id ?? ''}
          hasAppointmentsProvider={false}
          onAppointmentSubmitSuccess={noop}
        />
        <div className={classNames('mt-4 flow-root', tableContainerClass)}>
          <div className="inline-block min-w-full py-2 align-middle">
            <table className="min-w-full border-separate border-spacing-0">
              <thead>
                {table.getHeaderGroups().map(headerGroup => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map(header => (
                      <th
                        key={header.id}
                        scope="col"
                        className="sticky z-10 border-b border-gray-300 bg-gray-200  py-2 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                      >
                        {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                        {header.column.getCanFilter() ? (
                          <div>
                            <Filter column={header.column} table={table} />
                          </div>
                        ) : (
                          <></>
                        )}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>

              <tbody>
                {table.getRowModel().rows.map((row, index) => (
                  <AppointmentListRow
                    key={row.original.id}
                    appointment={row.original}
                    onRightClick={onRightClick}
                    row={row}
                    renderPrintLayout={renderPrintLayout}
                    isLast={index === table.getRowModel().rows.length - 1}
                    onSingleClick={onSingleClick}
                    onDoubleClick={onDoubleClick}
                    isActive={row.original === activeAppointment}
                    onGoToSchedule={onGoToSchedule}
                  />
                ))}
              </tbody>
            </table>
            <AppointmentListNotes appointment={activeAppointment} />
          </div>
        </div>

        {!renderPrintLayout && (
          <>
            {profile.capabilities.canToggleRightClick() && (
              <div className="mt-4 mb-6 print:hidden">
                <button
                  type="button"
                  onClick={toggleRightClick}
                  className="rounded-full bg-white px-2.5 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                >
                  {rightClickEnabled ? 'Disable' : 'Enable'} Right Click
                </button>
              </div>
            )}
          </>
        )}
      </>
    );
  } else {
    return <></>;
  }
};
