import React from 'react';
import { starLogoSvg } from './LoadingAnimatedSvg';

export type LoadingAnimatedProps = {
  size?: number;
  date?: string;
};

// // exponential decay function
// function probability(daysUntil: number): number {
//   if (daysUntil <= 0) {
//     return 1;
//   }
//   const initialAmount = 1;
//   daysUntil += 1;
//   const rate = 0.5;
//   return initialAmount * Math.pow(rate, daysUntil - 1);
// }

// const shouldShowThanksgiving = (year: number, month: number, day: number) => {
//   const firstOfNovember = new Date(year, 10, 1);
//   const dayOfWeek = firstOfNovember.getDay();
//   const firstThursday = 1 + ((4 - dayOfWeek + 7) % 7);
//   const thanksgivingDate = firstThursday + 21;
//   const daysBefore = 5;
//   if (month !== 11 || thanksgivingDate - day > daysBefore) {
//     return false;
//   }
//
//   const daysUntilThanksgiving = thanksgivingDate - day;
//   return Math.random() < probability(daysUntilThanksgiving);
// };
//
// const shouldShowChristmas = (year: number, month: number, day: number) => {
//   const daysBefore = 15;
//   const christmasDay = 25;
//
//   if (month !== 12 || christmasDay - day > daysBefore) {
//     return false;
//   }
//
//   const daysUntilChristmas = christmasDay - day;
//   return Math.random() < probability(daysUntilChristmas);
// };

const starSpinner = (ref: React.RefObject<SVGPolygonElement | SVGImageElement>) => {
  if (ref.current) {
    ref.current.style.transformOrigin = 'center';
    ref.current.style.transformBox = 'fill-box';
    ref.current.style.animation = 'spin 20s linear infinite reverse';
  }
};

const groupSpinner = (ref: React.RefObject<SVGGElement>) => {
  if (ref.current) {
    ref.current.style.transformOrigin = 'center';
    ref.current.style.transformBox = 'fill-box';
    ref.current.style.animation = 'spin 20s linear infinite';
  }
};

export const LoadingAnimated = ({ size, date }: LoadingAnimatedProps) => {
  const blueRef = React.useRef<SVGImageElement>(null);
  const redRef = React.useRef<SVGImageElement>(null);
  const yellowRef = React.useRef<SVGImageElement>(null);
  const greenRef = React.useRef<SVGImageElement>(null);
  const purpleRef = React.useRef<SVGImageElement>(null);
  const groupRef = React.useRef<SVGSVGElement>(null);

  React.useEffect(() => {
    starSpinner(blueRef);
    starSpinner(redRef);
    starSpinner(yellowRef);
    starSpinner(greenRef);
    starSpinner(purpleRef);
    groupSpinner(groupRef);
  }, []);

  const svgToUse = starLogoSvg;

  // const svgToUse = useMemo(() => {
  //   try {
  //     const d = date || format(new Date(), 'yyyy-MM-dd');
  //     const year = parseInt(d.slice(0, 4));
  //     const month = parseInt(d.slice(5, 7));
  //     const day = parseInt(d.slice(8, 10));
  //
  //     if (shouldShowChristmas(year, month, day)) {
  //       return snowFlakeSvg;
  //     }
  //
  //     if (shouldShowThanksgiving(year, month, day)) {
  //       return turkeySvg;
  //     }
  //   } catch (exception) {
  //     console.error('Error in svgToUse', exception);
  //   }
  //
  //   return starLogoSvg;
  // }, [date]);

  const base64StarSvg = (fill: string) => {
    const multiLineComponent = svgToUse(fill);
    return `data:image/svg+xml;base64,${btoa(multiLineComponent)}`;
  };

  return (
    <div className="flex justify-center mt-4">
      <svg ref={groupRef} viewBox="0 0 2200 2200" height="300">
        <g transform="translate(100,100)">
          <image ref={blueRef} href={base64StarSvg('#321DD2')} x="618" y="90" width="750" height="735" />
          <image ref={greenRef} href={base64StarSvg('#3C9E11')} x="1225" y="505" width="750" height="735" />
          <image ref={purpleRef} href={base64StarSvg('#89059D')} x="990" y="1175" width="750" height="735" />
          <image ref={yellowRef} href={base64StarSvg('#FAA703')} x="270" y="1175" width="750" height="735" />
          <image ref={redRef} href={base64StarSvg('#CB0A08')} x="25" y="505" width="750" height="735" />
        </g>
      </svg>
    </div>
  );
};
