import React, { useState } from 'react';
import { FormActionProps } from '../Forms/FormActionProps';
import { InputGroup } from '../Forms/InputGroup';
import { FieldValues, useForm, ValidationRule } from 'react-hook-form';
import useField from '../Forms/FieldHook';
import { phoneNumberRegex } from '../Forms/PhoneMaskHook';
import { Paralegal, useCreateParalegal, useDeleteParalegal, useUpdateParalegal } from '../../api/ParalegalApi';
import { PhoneInputGroup } from '../Forms/PhoneInputGroup';
import { EnterForm } from '../Forms/EnterForm';
import { useFlexworxAnciallry } from 'utils/flexworx-ancillary';
import { ButtonRowV2 } from '../Forms/ButtonRowV2';
import { LawOfficeInputGroup } from '../Forms/LawOfficeInputGroup/LawOfficeInputGroup';
import { AttorneysInputGroup } from '../Forms/AttorneysInputGroup/AttorneysInputGroup';

export type ParalegalFormParams = FormActionProps<Paralegal> & {
  defaultValues?: FieldValues | undefined;
};

export function ParalegalForm({ onSuccess, onUnrecoverableError, defaultValues, onDeleted }: ParalegalFormParams) {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    clearErrors,
    setValue,
    reset,
    watch,
  } = useForm({ shouldFocusError: true, defaultValues: defaultValues });

  const { lawOfficeFromId } = useFlexworxAnciallry();
  const lawOfficeInitialValue = lawOfficeFromId(defaultValues?.lawOfficeId);

  const isNew = !defaultValues?.id;
  const createParalegal = useCreateParalegal();
  const updateParalegal = useUpdateParalegal();
  const deleteParalegal = useDeleteParalegal();

  const apiAction = isNew ? createParalegal : updateParalegal;

  const onSubmit = async data => {
    const paralegal = await apiAction.mutateAsync(data);
    onSuccess(paralegal);
    reset();
  };

  const fieldArguments = (
    name: string,
    label: string,
    pattern?: ValidationRule<RegExp> | undefined,
    required?: string | ValidationRule<boolean> | undefined,
    setValueAs?: ((value: any) => any) | undefined,
  ) => {
    return { register, name, label, errors, clearErrors, setValue, pattern, required, setValueAs };
  };

  const firstName = useField<string>(fieldArguments('firstName', 'First Name'));
  const lastName = useField<string>(fieldArguments('lastName', 'Last Name'));
  const lawOfficeId = useField<string>(fieldArguments('lawOfficeId', 'Law Office'));
  const attorneyIds = useField<string[]>(fieldArguments('attorneyIds', 'Attorneys'));
  const phone = useField<string>(
    fieldArguments('phone', 'Phone', {
      value: phoneNumberRegex,
      message: 'Invalid Phone',
    }),
  );
  const fax = useField<string>(fieldArguments('fax', 'Fax', { value: phoneNumberRegex, message: 'Invalid Fax' }));
  const email = useField(fieldArguments('email', 'Email', { value: /^\S+@\S+$/i, message: 'Invalid Email' }));

  const header = isNew ? 'Create Paralegal' : 'Edit Paralegal';
  const submitText = isNew ? 'Create' : 'Update';

  const onDelete = async () => {
    if (defaultValues?.id) {
      await deleteParalegal.mutateAsync(defaultValues.id);
      if (onDeleted) {
        onDeleted(defaultValues as Paralegal);
      }
    }
  };

  const [isDisabled, setIsDisabled] = useState(false);

  const watchLawOfficeId = watch('lawOfficeId');
  const watchAttorneyIds = watch('attorneyIds', defaultValues?.attorneyIds ?? []);

  return (
    <EnterForm
      submitForm={handleSubmit(onSubmit)}
      setError={setError}
      onUnrecoverableError={onUnrecoverableError}
      className="space-y-8 divide-y divide-gray-200"
      setDisabled={setIsDisabled}
    >
      <div className="space-y-8 divide-y divide-gray-200">
        <div className="pt-2">
          <div>
            <h3 className="text-lg font-medium leading-6 text-gray-900 italic">{header}</h3>
            <p className="mt-1 text-sm text-gray-500">Please fill out as much information as possible.</p>
          </div>
          <div className="mt-6 mx-1 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-12">
            <div className="sm:col-span-12">
              <InputGroup {...firstName} type="text" placeholder="Lucian" />
            </div>
            <div className="sm:col-span-12">
              <InputGroup {...lastName} type="text" placeholder="Lloyd" />
            </div>
            <div className="sm:col-span-12">
              <InputGroup {...email} type="email" placeholder="lllyod@gmail.com" />
            </div>
            <div className="sm:col-span-6">
              <PhoneInputGroup {...phone} placeholder="(555) 555-5555" />
            </div>
            <div className="sm:col-span-6">
              <PhoneInputGroup {...fax} placeholder="(555) 888-9999" />
            </div>
            <div className="sm:col-span-12">
              <LawOfficeInputGroup
                {...lawOfficeId}
                defaultModel={lawOfficeInitialValue}
                lawFirmId={null}
                attorneyId={null}
                paralegalId={null}
              />
            </div>
            <div className="sm:col-span-12">
              <AttorneysInputGroup
                {...attorneyIds}
                attorneyIds={watchAttorneyIds}
                lawOfficeId={watchLawOfficeId}
                paralegalId={null}
                lawFirmId={null}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="pt-5">
        <div className="flex justify-end">
          <ButtonRowV2
            isDisabled={isDisabled}
            setIsDisabled={setIsDisabled}
            onUnrecoverableError={onUnrecoverableError}
            onSubmitText={submitText}
            onDelete={isNew ? undefined : onDelete}
          />
        </div>
      </div>
    </EnterForm>
  );
}
