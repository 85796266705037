import React, { useState } from 'react';
import { FormActionProps } from '../Forms/FormActionProps';
import { InputGroup } from '../Forms/InputGroup';
import { FieldValues, useForm, ValidationRule } from 'react-hook-form';
import useField from '../Forms/FieldHook';
import { LawFirm, useCreateLawFirm, useDeleteLawFirm, useUpdateLawFirm } from '../../api/LawFirmApi';
import { EnterForm } from '../Forms/EnterForm';
import { ButtonRowV2 } from '../Forms/ButtonRowV2';

export type LawFirmFormParams = FormActionProps<LawFirm> & {
  defaultValues?: FieldValues | undefined;
};

export function LawFirmForm({ onSuccess, onUnrecoverableError, defaultValues, onDeleted }: LawFirmFormParams) {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    clearErrors,
    setValue,
    reset,
  } = useForm({ shouldFocusError: true, defaultValues: defaultValues });

  const isNew = !defaultValues?.id;
  const createLawFirm = useCreateLawFirm();
  const updateLawFirm = useUpdateLawFirm();
  const deleteLawFirm = useDeleteLawFirm();

  const apiAction = isNew ? createLawFirm : updateLawFirm;

  const onSubmit = async data => {
    const lawFirm = await apiAction.mutateAsync(data);
    onSuccess(lawFirm);
    reset();
  };

  const fieldArguments = (
    name: string,
    label: string,
    pattern?: ValidationRule<RegExp> | undefined,
    required?: string | ValidationRule<boolean> | undefined,
  ) => {
    return { register, name, label, errors, clearErrors, setValue, pattern, required };
  };

  const name = useField(fieldArguments('name', 'Name', undefined, 'Name is required'));

  const header = isNew ? 'Create Law Firm' : 'Edit Law Firm';
  const submitText = isNew ? 'Create' : 'Update';

  const onDelete = async () => {
    if (defaultValues?.id) {
      await deleteLawFirm.mutateAsync(defaultValues.id);
      if (onDeleted) {
        onDeleted(defaultValues as LawFirm);
      }
    }
  };

  const [isDisabled, setIsDisabled] = useState(false);

  return (
    <EnterForm
      className="space-y-8 divide-y divide-gray-200"
      submitForm={handleSubmit(onSubmit)}
      setError={setError}
      onUnrecoverableError={onUnrecoverableError}
      setDisabled={setIsDisabled}
    >
      <div className="space-y-8 divide-y divide-gray-200">
        <div className="">
          <div>
            <h3 className="text-lg font-medium leading-6 text-gray-900 italic">{header}</h3>
            <p className="mt-1 text-sm text-gray-500">Please fill out as much information as possible</p>
          </div>
          <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-12">
            <div className="sm:col-span-6">
              <InputGroup {...name} type="text" placeholder="Corgan & Corgan" />
            </div>
          </div>
        </div>
      </div>
      <div className="pt-5">
        <div className="flex justify-end">
          <ButtonRowV2
            isDisabled={isDisabled}
            setIsDisabled={setIsDisabled}
            onUnrecoverableError={onUnrecoverableError}
            onSubmitText={submitText}
            onDelete={isNew ? undefined : onDelete}
          />
        </div>
      </div>
    </EnterForm>
  );
}
