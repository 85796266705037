import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import useApiClient from 'utils/apiClientHook';

export type ScheduleDefinitionBulkSmsPreview = {
  body: string;
  smsCount: number;
};

export type SmsReplacementsResponse = {
  replacements: BulkSmsReplacement[];
  additions: BulkSmsAddition[];
};

export type BulkSmsAddition = {
  name: string;
  value: string;
};

export type BulkSmsReplacement = {
  name: string;
  value: string;
};

export type SmsMessage = {
  patientId: string;
  appointmentDate: string | null;
  sentAt?: string;
  toNumber: string;
  body: string;
  errorMsg?: string;
  createdBy: string;
  status: string;
};

export const useSendSMSMessage = (patientId: string) => {
  const { computeClient: client } = useApiClient();
  const queryClient = useQueryClient();
  return useMutation<SmsMessage, AxiosError, SmsMessage>({
    mutationFn: request => client.post('/sms/message', request).then(response => response.data),
    onSuccess: appointment => {
      queryClient.refetchQueries(['sms-messages', patientId]);
    },
  });
};

export const useGetOneOffSMSMessages = (patientId: string) => {
  const { computeClient: client } = useApiClient();
  return useQuery(['sms-messages', patientId], () =>
    client.get<SmsMessage[]>(`/sms/patient/${patientId}`).then(response => response.data),
  );
};

export type NewScheduleDefinitionBulkSms = {
  scheduleDefinitionId: string;
  appointmentDate: string;
  body: string;
};

export type ScheduleDefinitionBulkSms = NewScheduleDefinitionBulkSms & { id: string };

export const useCreateBulkSms = () => {
  const { computeClient: client } = useApiClient();
  return useMutation<ScheduleDefinitionBulkSms, AxiosError, NewScheduleDefinitionBulkSms>({
    mutationFn: async function (request: NewScheduleDefinitionBulkSms) {
      const response = await client.post('/sms/schedule-definition', request);
      return response.data;
    },
    onSuccess: () => {},
  });
};

export const usePreviewBulkSms = () => {
  const { computeClient: client } = useApiClient();
  return useMutation<ScheduleDefinitionBulkSmsPreview, AxiosError, NewScheduleDefinitionBulkSms>({
    mutationFn: async function (request: NewScheduleDefinitionBulkSms) {
      const response = await client.post('/sms/schedule-definition/preview', request);
      return response.data;
    },
    onSuccess: () => {},
  });
};

export const useGetScheduleDefinitionBulkSmsPreview = () => {
  const { computeClient: client } = useApiClient();
  return useQuery(['sms/schedule-definition/replacements'], () =>
    client.get<SmsReplacementsResponse>('/sms/schedule-definition/replacements').then(response => response.data),
  );
};
